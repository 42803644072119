import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { VesselCrewService } from '../../shared/services/vessel-crew.service';
import { VesselCrews } from 'src/app/shared/Models/VesselCrew';
import {VesselService} from '../../shared/services/vessels.service';
import {VesselOption} from '../../shared/Models/VesselOption';
import {SpinnerService} from '../../shared/services/spinner.service';
import {User} from '../../shared/Models/User';
import {CrewService} from '../../shared/services/crewService';
import * as XLSX from 'xlsx';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { LoggerService } from 'src/app/shared/services/loger.service';

@Component({
  selector: 'app-vessel-crew',
  templateUrl: './vessel-crew.component.html',
  styleUrl: './vessel-crew.component.css',
})
export class VesselCrewComponent implements OnInit {
  VesselCrew: VesselCrews[];
  crew: User = new User() ;
  VesselOptions: VesselOption[] = [];
  showAddForm: boolean;
  showEditForm: boolean;
  @Output() showAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  selectedFile: File | null = null;
  selectedExcel:string;
  rankOptions:string[]=[
    'Master',
    'Chief Engineer',
    'Chief Officer',
    'Second Officer',
    'Second Engineer',
    'Third Officer',
    'Third Engineer',
    'Fourth Engineer',
    'Fifth Engineer',
    'Deck Cadet',
    'Electrician',
    'Motorman',
    'Chief Cook',
    'Able Body',
    'Pumpman',
    'Wiper',
    'Messman',
    'OS',
    'Bosun',
    'Welder',
    'Fitter'
  ]
  statusOptions = [true, false];

  constructor(
    private vesselcrewservice: VesselCrewService,
    private vesselservice: VesselService,
    public _router: Router,
    private _spinner: SpinnerService,
    private _loggerService:LoggerService,
    private _crewService: CrewService
  ) {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
     }));

    this.onCloneIconClick = this.onCloneIconClick.bind(this);

  }
  ngOnInit() {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
     }));
    this.getVessselType();
    this.showAddForm = false;

  }

  onCloneIconClick(e) {
    const clonedItem = { ...e.row.data };
    this.VesselCrew.splice(e.row.rowIndex, 0, clonedItem);
  }

  isDeleteIconVisible(e) {
    return true;
  }
  showAddVesselCrewPopup() {
    this.showAddForm = true;
  }
  showEditVesselCrewPopup() {
    this.showEditForm = true;
  }
  showAddProgramPopup() {
    this.showAddForm = true;
    this.showAddFormChange.emit(this.showAddForm);
  }
  onShowAddFormChange(e: any) {
    this.showAddForm = false;
  }
  reloadCrew(e: any){
    this.getCrew();
  }
  getVessselType() {
    this.vesselservice.getVessels().subscribe((res) => {
      this.VesselOptions = res.map((vessel) => ({
        id: vessel.id,
        name: vessel.name,
      }));
    });
  }
  getCrew() {
    this.vesselcrewservice.getVesselCrew().subscribe((res => {
      this.VesselCrew = res;
    }));
  }
  onRowRemoving(e: any) {
   this.vesselcrewservice.deleteData(e.data.id).subscribe((res) => {
     alert('Crew Removed!');
   }) ;
  }
  onRowUpdate(e: any) {
    this._spinner.showLoader();
    this.crew.designation = e.data.designation;
    this.crew.id = e.data.id;
    this.crew.vesselId = e.data.vessel.id;
    this.crew.firstName = e.data.firstName;
    this.crew.password = e.data.password;
    this.crew.isActive=e.data.isActive;
    this._crewService.addCrew(this.crew).subscribe((res) => {
        this._spinner.hideLoader();
      },
      (error) => {
        // Handle error respons
        this._spinner.hideLoader();
      }) ;
  }
  renderUserStatus(cellElement: any, cellInfo: any) {
    const isActive = cellInfo.value;
    const statusText = isActive ? 'Active' : 'Inactive';
    const color = isActive ? 'green' : 'red';

    // Apply the text and styling directly to the cell
    cellElement.innerHTML = `<span style="color: ${color};">${statusText}</span>`;
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    console.log('Selected File:', this.selectedFile);
    const reader = new FileReader();

    // Read file as Base64
    reader.readAsDataURL(this.selectedFile);

    reader.onload = () => {
      // Get Base64 string
      const base64String = reader.result as string;

      // Optionally, you can remove the "data:image/png;base64," part if the file isn't an image
      this.selectedExcel  = base64String.split(',')[1];

    }
  }
  uploadCrew() {
    if (!this.selectedFile) {
      alert('No file selected');
      return;
    }


    let formData = new FormData();
    formData.append('file', this.selectedFile); // Correctly append the file
    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });
    let file:excelUpload = new excelUpload();
    file.name="Test";
    file.file = this.selectedExcel; // Correctly append the file

    // Append any additional data, such as name
    formData.append('name', 'Test');

    this._spinner.showLoader();
    this.vesselcrewservice.uploadExcel(file).subscribe((res: ServerResponse) => {
      if (res.success) {
        this._spinner.hideLoader();
        this._loggerService.logSuccess("Uploaded Successfully");
      }else{
        this._spinner.hideLoader();
        this._loggerService.Error(res.error.message);
      }
    },(error:any)=>{
      this._spinner.hideLoader();
    });
  }

  downloadSampleExcel(): void {
    // Define sample data
    const sampleData = [
      ['Designation', 'FirstName','LastName', 'IMO Number','PassWord', 'IsActive'], // Header row
      ['Captain', 'John','Doe', '5678901', 'Password@1234',true],                     // Sample row 1
      ['Chief Engineer', 'Mike','Tyson', '3456789',  'Password@1234',false]              // Sample row 2
    ];

    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sampleData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SampleCrewData');

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'SampleCrewData.xlsx';
    link.click();
  }

}
export class excelUpload{
  name:string;
  file:string;
}
