import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Formio, FormioModule } from '@formio/angular';
import {
  DevExtremeModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxNumberBoxModule,
  DxResizableModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxDataGridModule,
  DxTileViewModule,
  DxToolbarModule,
  DxTreeListModule,
  DxSchedulerModule,
  DxValidatorModule,
  DxDateBoxModule,
  DxToastModule,
} from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthHeaderInterceptor } from './interceptors/http.interceptor';
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts';
import { AccountInformationComponent } from './pages/account-information/account-information.component';
import { HomeComponent } from './pages/home/home.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { FooterModule, LoginFormModule } from './shared/components';
import { AppInfoService, AuthService, ScreenService } from './shared/services';
import { RequestCache } from './shared/services/RequestCache';
import { CachingInterceptor } from './interceptors/caching.interceptor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { Service } from './app.service';
import { VesselsComponent } from './pages/vessels/vessels.component';
import { VesselCrewComponent } from './pages/vessel-crew/vessel-crew.component';
import { AddComponent as VesselsAddComponent } from './pages/vessels/add/add.component';
import { EditComponent as VesselEditComponent } from './pages/vessels/edit/edit.component';
import { AddComponent as VesselCrewAddComponent } from './pages/vessel-crew/add/add.component';
import { EditComponent as VesselCrewEditComponent } from './pages/vessel-crew/edit/edit.component';
import { ProgramComponent } from './pages/program/program.component';
import { AddComponent as ProgramAddComponent } from './pages/program/add/add.component';
import { EditComponent as ProgramEditComponent } from './pages/program/edit/edit.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ReportComponent } from './pages/report/report.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { VesselService } from './shared/services/vessels.service';
import { VesselCrewService } from './shared/services/vessel-crew.service';
import { ProgramService } from './pages/program/program.service';
import { SireCalendarComponent } from './pages/sire-calendar/sire-calendar.component';
import { SireCalenderService } from './pages/sire-calendar/sire-calendar.service';
import { AddEventComponent } from './pages/sire-calendar/add-event/add-event.component';
import { ReportService } from './pages/report/report.service';
import { FleetOverviewComponent } from './pages/report/fleet-overview/fleet-overview.component';
import { AuditPreparationComponent } from './pages/report/audit-preparation/audit-preparation.component';
import { DefectAnalysisComponent } from './pages/report/defect-analysis/defect-analysis.component';
import { CrewPerformanceComponent } from './pages/report/crew-performance/crew-performance.component';
import { HistoricalAuditDataComponent } from './pages/report/historical-audit-data/historical-audit-data.component';
import { UserActivityLogComponent } from './pages/report/user-activity-log/user-activity-log.component';
import { ChecklistComponent } from './pages/program/checklist/checklist.component';
import { QuestionComponent } from './pages/question/question.component';
import { ChapterQuestionComponent } from './pages/program/sprints/chapter-question/chapter-question.component';
import { DetailsComponent } from './pages/program/sprints/sprints.component';
import { CaptainService } from './shared/services/captain.service';
import { CaptainRoutingModule } from './captain-managment/captain-routing.module';
import { CrewQuestionComponent } from './pages/crew/crew-question/crew-question.component';
import { CrewChecklistQuestionsComponent } from './pages/crew/crew-checklist-questions/crew-checklist-questions.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {SpinnerComponent} from './shared/components/spinner/spinner.component';
import {SpinnerService} from './shared/services/spinner.service';
import { DefectComponent } from './pages/defect/defect/defect.component';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ImportsModule } from './import';
import { ZingchartAngularModule } from 'zingchart-angular';
import { WebcamModule } from 'ngx-webcam';
import { NgxPaginationModule } from 'ngx-pagination';
import { FullCalendarModule } from '@fullcalendar/angular';

declare var require: any;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserManagementComponent,
    AccountInformationComponent,
    VesselsComponent,
    VesselCrewComponent,
    VesselsAddComponent,
    VesselCrewAddComponent,
    ProgramComponent,
    ProgramAddComponent,
    VesselEditComponent,
    VesselCrewEditComponent,
    ProgramEditComponent,
    ReportComponent,
    SireCalendarComponent,
    AddEventComponent,
    FleetOverviewComponent,
    AuditPreparationComponent,
    DefectAnalysisComponent,
    CrewPerformanceComponent,
    HistoricalAuditDataComponent,
    UserActivityLogComponent,
    ChecklistComponent,
    QuestionComponent,
    ChapterQuestionComponent,
    DetailsComponent,
    CaptainRoutingModule.components,
    CrewQuestionComponent,
    CrewChecklistQuestionsComponent,
    SpinnerComponent,
    DefectComponent



  ],
  imports: [
    FullCalendarModule,
    NgxPaginationModule,
    WebcamModule,
    ImportsModule,
    CommonModule,
    DxDataGridModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    SideNavOuterToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    DxTreeListModule,
    DxTileViewModule,
    DxNumberBoxModule,
    DxToolbarModule,
    DxFormModule,
    DxTabPanelModule,
    DxCheckBoxModule,
    DxTemplateModule,
    DxDataGridModule,
    DevExtremeModule,
    DxResizableModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    HttpClientModule,
    FormioModule,
    FormsModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton:false,
      timeOut: 3000,
      positionClass: 'toast-middle-right',
      preventDuplicates: true,
    }),
    DxSchedulerModule,
    DxValidatorModule,
    DxTileViewModule,
    ReactiveFormsModule,
    DxDateBoxModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    FormioModule,
    DxToastModule,
    ZingchartAngularModule




  ],
  providers: [
    AuthService,
    ScreenService,
    CaptainService,
    ReportService,
    AppInfoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    RequestCache,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    Service,
    ToastrService,
    provideAnimations(),
    VesselService,
    VesselCrewService,
    ProgramService,
    SireCalenderService,
    ReportService,
    CaptainService,
    SpinnerService,
    MessageService,
    ConfirmationService,
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
