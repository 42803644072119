import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Defect } from 'src/app/shared/Models/Defect';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { LoggerService } from 'src/app/shared/services/loger.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { VesselService } from 'src/app/shared/services/vessels.service';
declare var $: any;
import { Modal } from 'bootstrap';
// import { exportDataGrid } from 'devextreme/excel_exporter';
// import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-defect',
  templateUrl: './defect.component.html',
  styleUrl: './defect.component.css'
})
export class DefectComponent implements OnInit {
@ViewChild('detailsModal') detailsModal: ElementRef;
defectList:Defect[]=[];
filteredDefectList:Defect[]=[];
selectedDefect: Defect= new Defect();
searchText: string = ''; // For binding the search input
currentPage = 1;
itemsPerPage: number = 25;
Math = Math;
remarks:string=null;

constructor(
private _vesselService:VesselService,
private _spinnerService:SpinnerService,
private _loggerService:LoggerService
){

}

  ngOnInit() {
    this._spinnerService.showLoader();
    this._vesselService.getDefect().subscribe((res:ServerResponse)=>{
      if(res.success){
        this._spinnerService.hideLoader();
        this.defectList=res.result;
        this.filteredDefectList = [...this.defectList];

      }
    },(error:Error)=>{
      this._spinnerService.hideLoader();
    })
  }

  filteredDefects() {
    const lowerSearchText = this.searchText.toLowerCase();
    return this.defectList.filter((defect) => {
      const question = defect.question?.text?.toLowerCase() || '';
      const userName = `${defect.user?.firstName} ${defect.user?.lastName}`.toLowerCase();
      const vesselName = defect.user?.vessel?.name?.toLowerCase() || '';
      const rank = defect.rank?.toLowerCase() || '';
      const statusName = defect.statusName?.toLowerCase() || '';
      return (
        question.includes(lowerSearchText) ||
        userName.includes(lowerSearchText) ||
        vesselName.includes(lowerSearchText) ||
        rank.includes(lowerSearchText) ||
        statusName.includes(lowerSearchText)
      );
    });
  }

  onActionClick(defect: any) {
    this.selectedDefect = defect;
    this.selectedDefect.chapterName = this.selectedDefect?.question?.text.split('.')[0] + ". " +this.selectedDefect.chapterName;
  }
  saveRemarks(){
    if(this.selectedDefect?.remarks==null ||  this.selectedDefect?.remarks == undefined || this.selectedDefect?.remarks.trim()==""){
      this._loggerService.logError("Please enter Remarks.");
      return;
    }
    this._spinnerService.showLoader();
    this._vesselService.SaveRemarks(this.selectedDefect.id,this.selectedDefect?.remarks).subscribe((res:ServerResponse)=>{
      this._spinnerService.hideLoader();
      if(res.success){
        this._loggerService.logSuccess("Remarks Saved successfully")
      }
    },(error:Error)=>{
      this._spinnerService.hideLoader();
    })
  }


  closeDefect(){
    this._spinnerService.showLoader();
    this._vesselService.closeDefect(this.selectedDefect.id).subscribe((res:ServerResponse)=>{
      this._spinnerService.hideLoader();
      if(res.success){
        this._loggerService.logSuccess("Defect has been Closed successfully.");
        this.selectedDefect.statusName = "Closed";
        this.selectedDefect.closedOn = new Date();
      }
    },(error)=>{
      this._spinnerService.hideLoader();
      this._loggerService.logError(error);
    });
    $('#detailsModal').modal('hide');
  }
  closedDefectModel(){
    $('#detailsModal').modal('hide');
    this.remarks=null;
  }
  onSearchChange() {
    this.filteredDefectList = this.defectList.filter((defect) =>
      defect.question?.text?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.user?.firstName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.user?.lastName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.rank?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.statusName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.user?.vessel?.name?.toLowerCase().includes(this.searchText.toLowerCase()) ||
      defect.statusName.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }
  get paginatedDefects() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredDefectList.slice(startIndex, startIndex + this.itemsPerPage);
  }
}

