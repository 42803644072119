import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CaptainService } from '../shared/services/captain.service';
import { VesselCrewService } from '../shared/services/vessel-crew.service';
import { AssignCrewDTO } from '../shared/Models/AssignCrewDTO';
import {SpinnerService} from '../shared/services/spinner.service';
import { ErrorDetail, ServerResponse } from '../shared/Models/ServerResponse';
import { ToastrService } from 'ngx-toastr';
import { LoggerService } from '../shared/services/loger.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-captain',
  templateUrl: './captain.component.html',
  styleUrls: ['./captain.component.css']
})
export class CaptainComponent implements OnInit {

  constructor(
    private _captainService:CaptainService,
    private _vesselCrewService:VesselCrewService,
    private _loggerService:LoggerService,
    private  _spinner : SpinnerService,
    private _location: Location,
  ) { }
  vesselQuestions: any[] = [];
  assignedCrew: any[] = [];
  crewList:any[]=[];
  filteredCrewList:any[]=[];
  isEditing: boolean = false;
  crewQuestion :AssignCrewDTO = new AssignCrewDTO();
  selectedCrew: number;
  selectedRank: number;
  rankList:string[]=[];
  filteredRankList:string[]=[];
  roleId:number=null;
  ngOnInit() {
    let sprintId = localStorage.getItem("SprintId");
    this.roleId = parseInt(localStorage.getItem("roleId"));
    if(sprintId!=null || undefined){
      this.getVesselQuestionBySprintId(parseInt(sprintId));
    }
    else{
      this.getVesselQuestion();
    }
  }
  getVesselQuestionBySprintId(sprintId:number){
    this._spinner.showLoader();
    this._captainService.getVesselQuestionBySprintId(sprintId).subscribe((res:ServerResponse)=>{
      this._spinner.hideLoader();
      if(res.success){
        this.vesselQuestions = res.result;
      }
    });
  }
  getVesselQuestion(){
    this._spinner.showLoader();
    this._captainService.getVesselQuestion().subscribe((res:ServerResponse)=> {
      if(res.success){
        this.vesselQuestions = res.result;
        this._vesselCrewService.getVesselCrew().subscribe((res=> {
        this.crewList = res;
        this.rankList = [...new Set(this.crewList.map(crew => crew.designation))];
        this.filteredRankList = this.rankList;
        this._spinner.hideLoader();
        this.crewList = this.crewList.filter(x => x.roleId === 3 || x.roleId ===  2);
      }));
      }
      else{
        this._spinner.hideLoader();
      }
    },
  (error)=>{
    this._loggerService.logError(error.error.message)
    this._spinner.hideLoader();
  });
  }
  onEditingStart(event: any) {
    if(event.data.statusId==7){
      this._loggerService.logError("Can not Assign a completed question.");
      event.cancel=true;
      return;
    };
    if(event.data.user!=null){
       this.filteredRankList = this.rankList.filter(x=>x==event.data.user.designation);
    }

    this.resetForm();
    this.isEditing = true;
    this.initializeForm(event.data);
    this.crewQuestion.sprintId = event.data.sprintId;
    this.crewQuestion.questionId = event.data.questionId;
    this.crewQuestion.id = event.data.id;
    if(event.data.user!=null){
      this.crewQuestion.isALreadyAssigned=true;
    }
  }

  onInitNewRow(event: any) {
    this.resetForm();
    this.isEditing = true;
  }
  initializeForm(data: any) {
    // initialize form with data if editing
    this.selectedCrew = data.crewId || null;
    this.selectedRank = data.rankId || null;
  }


  resetForm() {
    this.selectedCrew = null;
    this.selectedRank = null;
  }


  onRowUpdating(event: any): void {
    // Handle the row updating event
  }
  onRowRemoving(e: any): void {
    const rowData = e.data; // Get the data of the row being removed

  }
  onAssignClick(event:any) {
    if(this.selectedCrew==null){
      this._loggerService.logError("Please select crew");
      return;
    }

    this.crewQuestion.crewId=this.selectedCrew;
    this._spinner.showLoader();
    this._vesselCrewService.assignQuestion(this.crewQuestion).subscribe((res:ServerResponse)=>{
      if(res.success){
        this._spinner.hideLoader();
        this._loggerService.logSuccess("Crew Assigned Successfully");
        this.getVesselQuestion();
      }
    },(error)=>{
      this._loggerService.logError(error.error.message);
      this._spinner.hideLoader();
    })
  }
  handleRowUpdated(event: any) {
    // Your custom save logic here
  }
  onCrewChanged(event: any) {
    const crew = this.crewList.find(c => c.id === parseInt(event) );
    if (crew) {
      this.selectedRank = crew.id;
    }
  }
  onRankChanged() {
  this.selectedCrew=null;
  this.filteredCrewList=this.crewList.filter(x=>x.designation==this.selectedRank)
  }
  getAssignedUserName(data: any) {
    return  data.user
           ? data.user.firstName
           : 'Un-Assigned';
  }
  getAssignedRank(data: any) {
    return  data.user
           ? data.user.designation
           : '-NA-';
  }
  getStatus(data:any){
    switch (data.statusId) {
      case 5:
        return 'New';
      case 6:
        return 'In-Progress';
      case 7:
        return 'Completed';
      // Add more cases as needed
      default:
        return '-NA-';
  }
 }
 back() {
  //this._router.navigate(["/question"]);
  this._location.back();
}

}
