import { Program } from "./Program";
import { Question } from "./Sprint";

export class Defect {
  referenceNumber: string | null;
  tentativeClosureDate: Date;
  chapterId: number;
  chapterName: string;
  rank: string;
  crewId: number;
  clientId: number;
  statusId: number;
  statusName: string;
  closedBy: number;
  closedOn: Date;
  sprintAnswerId: number;
  sprintChecklistQuestionId: number;
  questionId: number;
  programId: number;
  program: Program;
  id: string;
  creatorUserId: string;
  creationTime: Date;
  lastModifierUserId: string | null;
  lastModificationTime: Date | null;
  isActive: boolean;
  roleId: number | null;
  question:Question;
  timeElapsed:string;
  user:any;
  remarks:string;
  sprintAnswer:any;

}
