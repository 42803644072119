import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Events } from "src/app/shared/Models/Events";

export const Event: Events[] = [

];
@Injectable()
export class SireCalenderService {
  private toastr: ToastrService;
  getEvent(): Events[] {
    return Event;
  }
  saveData() {
    this.toastr.success("Data saved successfully!");
  }
}
