import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private hubConnection: signalR.HubConnection;

  constructor(private http: HttpClient) {
    this.hubConnection = new signalR.HubConnectionBuilder()
            //.withUrl('https://sire.esquire.co.in/chat') // No "/api" prefix if backend is set up without it
            .withUrl('http://localhost:40671/chatHub') // No "/api" prefix if backend is set up without it

            .build();


  this.hubConnection
  .start()
  .then(() => {
    this.hubConnection.invoke("JoinGroup", "Hi");
  })
  .catch(err => console.error('Error connecting to SignalR hub:', err));
  }

  startConnection() {
    this.hubConnection
    .start()
    .then(() => {
      this.hubConnection.invoke('JoinGroup', 'groupName');
    })
    .catch(err => console.error('Error connecting to SignalR hub:', err));
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      this.hubConnection
        .start()
        .then(() => console.log("SignalR connection started"))
        .catch(err => console.error("Error starting SignalR connection: ", err));
    } else {
      console.log("Connection already started");
    }
  }

  invoke(){
    this.hubConnection.invoke('SendPrivateMessage', "userId", "message")
        .catch(err => console.error(err));
  }
  onReceiveMessage(callback: (user: string, message: string) => void) {
    this.hubConnection.on('ReceiveMessage', callback);
  }

  sendMessage(user: string, message: string) {
    this.hubConnection.invoke('SendMessage', user, message).catch(err => console.error(err));
  }

  getChatHistory(senderId: number, receiverId: number) {
    return this.http.get(`/api/chat/history/${senderId}/${receiverId}`);
  }
  addReceiveMessageListener() {
    this.hubConnection.on("ReceiveMessage", (user: string, message: string) => {
      console.log("Message received from:", user, message);
      // Handle received messages (e.g., add to a message list in the component)
    });
  }
  closeConnection() {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.stop()
        .then(() => console.log("SignalR connection stopped"))
        .catch(err => console.error("Error stopping SignalR connection: ", err));
    } else {
      console.log("Connection is already disconnected");
    }
  }
   // Notify others that the user is typing
   notifyTyping(user: string) {
    this.hubConnection.invoke('NotifyTyping', user).catch(err => console.error(err));
  }
  // Listen for typing notifications
  onUserTyping(callback: (user: string) => void) {
    this.hubConnection.on('ReceiveTypingNotification', (user) => {
      callback(user);
    });
  }
}
