import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AppInfoService } from "src/app/shared/services";
import { ReportService } from "src/app/shared/services/report.service";
import { ServerResponse } from "src/app/shared/Models/ServerResponse";
import { ZingchartAngularModule } from 'zingchart-angular';
import ZingchartAngular from "zingchart-angular/zingchart";

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit,OnDestroy  {
  config: ZingchartAngular.graphset ;
  crewDefectCount :  ZingchartAngular.graphset ;
  urlProfile = "api/personalinfo";
  urlBsinfo = "api/BusinessInfo";
  urlFile = "api/file";
  toolbaritems: any[];

  icinfoData: any;
  personalUsername: string;
  personalEmail: string;
  personalFullname: string;
  personalFirstname: string;
  personalLastname: string;
  isRTO: any = false;
  personalRole: string;
  personaldataupdate: any;
  businessdataupdate: any;
  updatePersonalData: any;
  businessinfoupdate: boolean = false;
  editBusinessInfo: boolean;
  editPersonalInfo: boolean;
  popupVisible: boolean;
  userImage: any;
  userImageUrl: any;
  businessImage: any;
  businessImageUrl: any;
  defaultUserImage: string = "assets/no-user-img.jpg";
  defaultBusinessLogo: string = "assets/no-company-img.jpg";
  reminderForLicense: boolean;
  reminderForInsurance: boolean;
  licenseExpiryDate: string;
  insuranceExpiryDate: string;
  newPasswordDetails = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  buttonOptions: any = {
    text: "Update",
    type: "default",
    useSubmitBehavior: true,
  };
  programs: any[];
  vessels: number;
  crewData: any[];
  programStatus: any[];
  totalCrewCount: number;
  pieChartData: { rank: string, count: number }[];
  chartData: any;
  chartOptions: any;
  roleId:string;
  questionStatusReport:any;
  assignedQuestionReports:any;
  totalSeconds: number = (90 * 3600) + (23 * 60) + 12; // 90:23:12 in seconds
  formattedTime: string = '';
  interval: any;
  sprintName:string;


  constructor(
    private http: HttpClient,
    public _router: Router,
    private _reportService:ReportService,
    public appInfo: AppInfoService,

  ) {

  }
  vesselSprintDatas: any[] = [];
  chartDefectData: any[] = [];
  chartDataRiskScore :any[] = [];
  ngOnInit() {
    this.roleId = localStorage.getItem("roleId");
    if(this.roleId=="2"){
      this.getAssignedQuestionReport();
      this. getCrewDefectData();
    }else if(this.roleId=="1"){
      this.getSprintCountByVessel();
      this.getVesselDefectData();
      this.getDefectVesselScore();
      this.fetchInspectionData();
      this.toolbar();
      this.popupVisible = false;
    }
    else{
      this.getQuestionStatusReport();
      this.getAssignedQueonReportCrew();
    }

  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


  passwordComparison = () => {
    return this.newPasswordDetails.newPassword;
  };

  customizePoint(point: any): any {
    const riskScore = point.value;
    if (riskScore <= 4) {
      return { color: '#4CAF50' }; // Green for scores <= 4
    } else if (riskScore > 4 && riskScore <= 10) {
      return { color: '#FFEB3B' }; // Yellow for scores between 4 and 10
    } else {
      return { color: '#F44336' }; // Red for scores > 10
    }
  }




  reminder(expiryDate) {
    var dateToday = new Date();
    var dateDiff =
      expiryDate.getMonth() -
      dateToday.getMonth() +
      12 * (expiryDate.getFullYear() - dateToday.getFullYear());
    if (dateDiff <= 1) {
      return true;
    } else {
      return false;
    }
  }

  toolbar() {
    this.toolbaritems = [
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-cursor'><b>Home / </b></div>";
        },
        onClick: () => {
          this._router.navigate(["/home"]);
        },
      },
      {
        location: "before",
        template: () => {
          return "<div class='toolbar-label'><b>Account Information  </b></div>";
        },
      },
    ];
  }
  getSprintCountByVessel(){
    this._reportService.getSprintCountByVessel().subscribe((res:ServerResponse)=>{
      if(res.success){
        this.vesselSprintDatas = res.result;
      }
   })
  }
  getVesselDefectData(){
    this._reportService.getVesselDefectData().subscribe((res:ServerResponse)=>{
      if(res.success){
        this.chartDefectData = res.result;
      }
   })
  }
  getDefectVesselScore(){
    this._reportService.getDefectVesselScore().subscribe((res:ServerResponse)=>{
      if(res.success){
        this.chartDataRiskScore = res.result;
      }
   })
  }

  prepareChartData(data: any[]) {
    const labels = data.map((item) => item.vesselName);
    const values = data.map((item) => {
      if (!item.date) return 0; // Default to 0 if no upcoming inspection
      const today = new Date();
      const inspectionDate = new Date(item.date);
      const timeDiff = inspectionDate.getTime() - today.getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Days until inspection
    });

    this.chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Days Until Inspection',
          data: values,
          backgroundColor: ['#808080', '#FF5733', '#3498DB', '#8E44AD'], // Adjust as needed
          borderColor: ['#666666', '#D44622', '#2471A3', '#76448A'], // Adjust as needed
          borderWidth: 1,
        },
      ],
    };
  }
  fetchInspectionData() {
    this._reportService.getUpcomingInspections().subscribe((res:ServerResponse)=>{
      this.prepareChartData(res.result);
      this.chartData = res.result.map(item => {
        const today = new Date();
        const inspectionDate = item.date ? new Date(item.date) : null;
        const daysUntilInspection = inspectionDate
          ? Math.ceil((inspectionDate.getTime() - today.getTime()) / (1000 * 3600 * 24))
          : 0; // Default to 0 if no upcoming inspection

        return {
          vesselName: item.vesselName,
          daysUntilInspection
        };
      })

    });

  }


  customizeLabelText(e: any): string {
    // Display days on one line and vessel name on a new line
    return `${e.valueText} days\n${e.argumentText}`;
  }

  customizeTooltip(e: any): any {
   console.log(e)
    return {
      text: `${e.item.argument}: ${e.valueText} days`
    };
  }
   parseTime(timeString: string): number {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  }

  getAssignedQuestionReport(){
    this._reportService.getAssignedQuestionReport().subscribe((res:ServerResponse)=>{
      let serverData= res.result;
      this.sprintName = res.result[0].sprintName;
      this.totalSeconds = this.parseTime(res.result[0].timeLeft); this.totalSeconds = this.parseTime(res.result[0].timeLeft);
      this.updateFormattedTime();
      this.startCountdown();
      this.config =
      {
        type: "bar3d",
        "3d-aspect": {
          depth: 40,
          true3d: false
        },
        plot: {
          stacked: true
        },
        labels: [
          {
            text: "Questions Assigned",
            backgroundColor: "#6666FF", // Bar fill color (single)
            alpha: 0.9,
            x: 100, // Adjust to center horizontally
            y: 10, // Position at the top of the chart
            color: "#FFFFFF",
            fontSize: "12px", // Adjust font size // Rotate label to avoid overlap
          },
          {
            text: "Questions Completed",
            backgroundColor: "#FF0066",
            x: 300, // Adjust to center horizontally
            y: 10, // Position at the top of the chart
            padding:"5px",
            alpha: 0.8,
            color: "#FFFFFF",
            fontSize: "12px", // Adjust font size
             // Rotate label to avoid overlap
          },
        ],


        scaleX: {
          label: { /* Scale Title */
            text: 'Questions Assigned vs Questions Completed for Each Crew',
          },
          labels: serverData.map((item) => item.crewName),  /* Scale Labels */

        },
        series: [
          {
            values: serverData.map((item) => item.assignedQuestion), // Extract assigned questions
            text: "Questions Assigned",
            backgroundColor: "#6666FF", // Bar fill color (single)
            alpha: 0.7, // Transparency
          },
          {
            values: serverData.map((item) => item.completedQuestion), // Extract completed questions
            text: "Questions Completed",
            backgroundColor: "#6666FF #FF0066", // Bar fill color (gradient)
            alpha: 0.3, // Transparency
          },
        ],
      };
      })
  }
  getCrewDefectData(){
    this._reportService.getCrewDefectData().subscribe((res:ServerResponse)=>{
      if(res.success){
        let seriesData: ChartSeries[] = res.result.map((data) => ({
          values: [data.count], // The count value to be used in the series
          text: data.crewlName,  // The name to be used as the text label
        }));
        this.crewDefectCount = {
          type: 'pie',
          title: {
            text: 'Crew Defect Count',
          },
          legend: {
            x: '75%',
            y: '25%',
            borderWidth: 1,
            borderColor: 'gray',
            borderRadius: '5px',
            header: {
              text: 'Legend',
              fontFamily: 'Georgia',
              fontSize: 12,
              fontColor: '#3333cc',
              fontWeight: 'normal',
            },
            marker: {
              type: 'circle',
            },
            toggleAction: 'remove',
            minimize: true,
            icon: {
              lineColor: '#9999ff',
            },
            maxItems: 30,
            overflow: 'scroll',
          },
          plotarea: {
            marginRight: '30%',
            marginTop: '15%',
          },
          plot: {

            animation: {
              onLegendToggle: true,
              effect: 5,
              method: 1,
              sequence: 1,
              speed: 1,
            },

            valueBox: {
              text: '%v', // Shows the value in the pie chart
              fontSize: 12,
              fontFamily: 'Georgia',
              fontWeight: 'normal',
              placement: 'out',
              fontColor: 'gray',
            },
            tooltip: {
              text: '%t: %v', // %t shows crewName, %v shows the count
              fontColor: 'black',
              fontFamily: 'Georgia',
              textAlpha: 1,
              backgroundColor: 'white',
              alpha: 0.7,
              borderWidth: 1,
              borderColor: '#cccccc',
              borderRadius: '10px',
              padding: '10%',
              placement: 'node:center',
            },
            borderWidth: 1,
            borderColor: '#cccccc',
            lineStyle: 'dotted',
          },
          "series": seriesData,
        };


      }
    })
  }
  getQuestionStatusReport(){
    this._reportService.getQuestionStatusReport().subscribe((res:ServerResponse)=>{

      this.questionStatusReport = [
        { text: 'Completed', value: res.result.completedCount,color: '#6fcf97'},
        { text: 'Pending', value: res.result.pendingCount,color:  '#f2c94c'},
        { text: 'Reassigned', value: res.result.reassignedCount,color:  '#56ccf2' },
      ];
    })
  }
  getAssignedQueonReportCrew(){
    this._reportService.getAssignedQueonReportCrew().subscribe((res:ServerResponse)=>{
     this.assignedQuestionReports =res.result;

    })
  }

   startCountdown(): void {
    this.interval = setInterval(() => {
      // if (this.totalSeconds > 0) {
        this.totalSeconds--;
        this.updateFormattedTime();
      // } else {
      //   clearInterval(this.interval); // Stop the timer when it reaches 0
      // }
    }, 1000);
  }

   updateFormattedTime(): void {
    const hours = Math.floor(this.totalSeconds / 3600);
    const minutes = Math.floor((this.totalSeconds % 3600) / 60);
    const seconds = this.totalSeconds % 60;

    this.formattedTime = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

   pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

}
export class ChartSeries {
  values: number;
  text: string;
}
export class StatusData{
  value:number;
  text:string;
  color:string;

}
