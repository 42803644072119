import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { SireCalenderService } from "./sire-calendar.service";
import { Events } from "src/app/shared/Models/Events";
import { ProgramService } from "../program/program.service";
import { DxSchedulerComponent } from "devextreme-angular";
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: "app-sire-calendar",
  templateUrl: "./sire-calendar.component.html",
  styleUrls: ["./sire-calendar.component.css"],
})
export class SireCalendarComponent implements OnInit {
  Event: Events[];
  showAddForm: boolean = false;
  currentDate: Date = new Date();
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',

    weekends: false,
    events: [
      { title: 'Event 1', start: '2025-01-01', end: '2025-01-02' },
      { title: 'Event 2', start: '2025-01-05T10:00:00', end: '2025-01-05T12:00:00' },
      { title: 'Event 3', start: '2025-01-10', allDay: true },
    ],
  };
  constructor(
    private calenderservice: SireCalenderService,
    private _programService:ProgramService,
    private cdr: ChangeDetectorRef
  ) {
   // this.getInspection()
    //this.Event = calenderservice.getEvent();
  }
  ngOnInit() {
    this.getInspection()
  }

  showAddEventPopup() {
    this.showAddForm = true;
  }
  isDeleteIconVisible(e) {
    return true;
  }
  onCellDoubleClick(event: any) {
    //this.showAddEventPopup();
    this.cdr.detectChanges();
  }
  getInspection(){
    this._programService.getInspection().subscribe((res=>{
      if(res.success){

        let data=  res.result.map((x)=>{
          return {
            title:x.name + " "+ x.vesselName.trim(),
            start:new Date(x.startDate),
            end : new Date(x.startDate)
          };
         });
         this.Event = data;

         console.log(this.Event);
        this.cdr.detectChanges();
        this.calendarOptions =  {
          plugins: [dayGridPlugin],
          initialView: 'dayGridMonth',

          weekends: true,
          events:data,
        };

      }
    })
    ,(error:Error)=>{

    })
  }
  handle(e) {
    console.log(e);
  }
}
