import { Component, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ImportsModule } from 'src/app/import';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DxDataGridModule, DxButtonModule, DxPopupModule, DxSelectBoxModule, DxDateBoxModule,DxDateBoxComponent, DxToastModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';
import { VesselCrewService } from 'src/app/shared/services/vessel-crew.service';
import { VesselService } from 'src/app/shared/services/vessels.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { LoggerService } from 'src/app/shared/services/loger.service';
import { Inspection } from 'src/app/shared/Models/Inspection';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgramService } from '../program/program.service';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { catchError, of, tap } from 'rxjs';
import { DxTextBoxTypes } from 'devextreme-angular/ui/text-box';
import { DatePipe } from '@angular/common';

interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
}


@Component({
  selector: 'app-inspection',
  standalone: true,
  imports: [ImportsModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxToastModule,
    DxValidationGroupModule, DxValidatorModule
  ],
  providers:[MessageService,DatePipe],
  templateUrl: './inspection.component.html',
  styleUrl: './inspection.component.css'
})
export class InspectionComponent implements OnInit{
  constructor(private vesselservice:VesselService,
    private _spinnerService:SpinnerService,
    private _loggerService:LoggerService,
    private _programService:ProgramService,
    private fb: FormBuilder,
    private datePipe: DatePipe


  ) {
    // Use MessageService here
    this.inspectionForm = this.fb.group({
      name: ['', Validators.required],
      vesselId: ['', Validators.required],
      inspectorName: [''],
      inspectionCompany: [''],
      oilMajor: [''],
      operation: [null] ,
      placeOfInspection: [''],
      startDate: ['', Validators.required]
    });

  }
  cols!: Column[];
  VesselOptions: any[];
  inspection:Inspection= new Inspection();
  inspectionList:Inspection[]=[];
  displayAddInspectionDialog:boolean=false;
  displayConfirmDialog: boolean = false;
  inspectionForm: FormGroup;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  isEditMode: boolean = false;
  lastInspectionDate:any;
  operationOptions = [
    { id: 1, name: 'Load' },
    { id: 2, name: 'Discharge' },
    { id: 3, name: 'Others' }
  ];
  form: FormGroup;

  ngOnInit(): void {
    this.cols = [
      { field: 'vessel', header: 'Vessel' },
      { field: 'startDate', header: 'Start Date' },
      { field: 'endDate', header: 'End Date' }
  ];
  this.getVessel();
  this.getInspection();
  }
  confirm() {

  }

  showAddInspectionDialog() {
    this.displayAddInspectionDialog = true;
    this.isEditMode = true;
  }



  saveInspection() {
    if(this.inspection.name==""|| this.inspection.name==null ){
      this._loggerService.logError("Please enter name");
      return;
    }

    const startDate = new Date(Date.UTC(
      this.inspection.startDateC.getFullYear(),
      this.inspection.startDateC.getMonth(),
      this.inspection.startDateC.getDate()
  ));
// Format dates as 'YYYY-MM-DD' if necessary for the API
this.inspection.startDate = startDate.toISOString().split('T')[0];
  this._spinnerService.showLoader();
  this.inspection.vesselName = this.VesselOptions.find(x=>x.id==this.inspection.vesselId).name;
  this._programService.saveInspection(this.inspection).pipe(
    tap((res: ServerResponse) => {
      if (res.success) {
        this._spinnerService.hideLoader();
        this._loggerService.logSuccess("Inspection Saved");
        this.inspection= new Inspection();
        this.getInspection();
      }
    }),
    catchError((error: any) => {
      this._spinnerService.hideLoader();
      this._loggerService.logError(error.error.message);
      // Optionally handle the error here (e.g., log or show error message)
      return of(null); // or throwError, depending on your error-handling preference
    })
  ).subscribe();
    this.displayAddInspectionDialog = false;
  }
  closeInspection(){
    this.displayAddInspectionDialog = false;
    this.inspection= new Inspection();
  }
  getVessel(){
    this.vesselservice.getVessels().subscribe((res) => {
      this.VesselOptions = res.map((vessel) => ({
        id: vessel.id,
        name: vessel.name,
      }));
    });
  }
  getInspection(){
    this._programService.getInspection().subscribe((res=>{
      if(res.success){
        this.inspectionList= res.result;
      }
    })
    ,(error:Error)=>{

    })
  }
  onVesselChange(data:any){
    this.minDate = new Date();
    this.lastInspectionDate=null;
    const latestInspection = this.inspectionList
    .filter(x => x.vesselId === data.value)
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0]; // Get the most recent start date
    this.lastInspectionDate = this.datePipe.transform(latestInspection.startDate, 'dd/MM/yyyy'); ;

  if (latestInspection) {
    const latestStartDate = new Date(latestInspection.startDate);
    latestStartDate.setMonth(latestStartDate.getMonth() + 5); // Add 5 months to the latest start date
    this.minDate = latestStartDate;
  } else {
    this.minDate = this.minDate; // Set to null or handle the case where no inspection is found
  }

  }
  onEditingStart(event: any) {
    if (new Date(event.data.startDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
      this._loggerService.logError("Can not edit past inspection!");
      event.cancel = true;
      return;
    }
    this.maxDate=event.data.startDate;
  }
  onRowUpdating(event: any) {
    // Merge old data with new data to get the full updated row
    const updatedRow = { ...event.oldData, ...event.newData };
    this._spinnerService.showLoader();
    this._programService.updateInspection(updatedRow).subscribe((res:ServerResponse)=>{
      if(res.success){
        this._spinnerService.hideLoader();
        this._loggerService.logSuccess("Inspection is updated");
      }
    },(error:any)=>{
      this._spinnerService.hideLoader();
      this._loggerService.logError(error.error.message);
    })
  }
  // getOperation(data:any){
  //   this. operationOptions = [
  //     { id: 1, name: 'Load' },
  //     { id: 2, name: 'Discharge' },
  //     { id: 3, name: 'Others' }
  //   ];
  //   var a= this.operationOptions.filter(x=>x.id==data.operation);
  //   if(a.length>0){
  //     return a[0].name;
  //   }
  //   else return null;
  // }

  getOperation(data: any): string | null {
    this.operationOptions = [
      { id: 1, name: 'Load' },
      { id: 2, name: 'Discharge' },
      { id: 3, name: 'Others' }
    ];
    const match = this.operationOptions.find(option => option.id == data.operation);
    return match ? match.name : null;
  }
  onOperationChange(cellInfo: any, event: any): void {
    const newValue = event.value;
    cellInfo.setValue(newValue); // Update the operation value
  }




}



