// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  margin: 0 auto;
  display: block;
}
.input-group {
  border: 1px solid #ced4da;
  border-radius: 20px; /* Rounded corners */
  overflow: hidden; /* Ensures rounded corners apply to the whole input group */
}

.input-group-text {
  background-color: transparent;
  border: none;
}

.form-control {
  padding: 0.5rem 0.75rem;
  border: none;
}

.form-control:focus {
  box-shadow: none; /* Removes default focus outline */
  outline: none;
}

.input-group:hover {
  border-color: #adb5bd; /* Subtle hover effect */
}

`, "",{"version":3,"sources":["webpack://./src/app/pages/defect/defect/defect.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,mBAAmB,EAAE,oBAAoB;EACzC,gBAAgB,EAAE,2DAA2D;AAC/E;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,gBAAgB,EAAE,kCAAkC;EACpD,aAAa;AACf;;AAEA;EACE,qBAAqB,EAAE,wBAAwB;AACjD","sourcesContent":[".btn-primary {\r\n  margin: 0 auto;\r\n  display: block;\r\n}\r\n.input-group {\r\n  border: 1px solid #ced4da;\r\n  border-radius: 20px; /* Rounded corners */\r\n  overflow: hidden; /* Ensures rounded corners apply to the whole input group */\r\n}\r\n\r\n.input-group-text {\r\n  background-color: transparent;\r\n  border: none;\r\n}\r\n\r\n.form-control {\r\n  padding: 0.5rem 0.75rem;\r\n  border: none;\r\n}\r\n\r\n.form-control:focus {\r\n  box-shadow: none; /* Removes default focus outline */\r\n  outline: none;\r\n}\r\n\r\n.input-group:hover {\r\n  border-color: #adb5bd; /* Subtle hover effect */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
